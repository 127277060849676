<template>
  <div v-if="$ability.can('read', this.$route.meta.resource)">
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">

        <!-- <p>{{ appData }}</p> -->

        <b-form-textarea
          id="textarea-default"
          plaintext
          placeholder="Textarea"
          :value="text"
          rows="6"
        />
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="primary"
          @click="doCopy"
        >
          Copy!
        </b-button>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BButton, BFormTextarea, BCardText,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import storeModule from '@/store/root/application'
import debounce from 'debounce'

const STORE_MODULE_NAME = 'application'

export default {
  components: {
    BCard,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    BCardText,
    BFormTextarea,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
    }
  },
  computed: {
    appData() {
      return store.state[STORE_MODULE_NAME].respDataAppLication
    },
    text() {
      return this.appData !== null ? `class AppKey { \n String tokenApplication = "${this.appData.token}"; \n String applicationId = "${this.appData._id}"; \n String companyId = "${this.appData.companyId}"; \n String franchiseId = "${this.appData.franchiseId}"; \n}` : ''
    },
  },
  created() {
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)
    const obj = {
      id: this.$route.params.id,
    }

    this.show = true
    store
      .dispatch(`${STORE_MODULE_NAME}/view`, obj)
      // eslint-disable-next-line no-unused-vars
      .then(result => {
        this.show = false
      }).catch(error => {
        this.show = false
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: this.$t('Error'),
            icon: 'ErrorIcon',
            variant: 'danger',
            text: this.$t(error.response.data.message),
          },
        })
      })
    this.typing = debounce(this.typing, 500)
  },
  destroyed() {
    if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
  },
  setup() {
    return { STORE_MODULE_NAME }
  },
  methods: {
    doCopy() {
      this.$copyText(this.text).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Text copied',
            icon: 'BellIcon',
          },
        })
      },
      // eslint-disable-next-line no-unused-vars
      e => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Can not copy!',
            icon: 'BellIcon',
          },
        })
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.v-select-custom {
  display: inline-block;
  width: 50;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.b-col-custom {
  margin-top: 0.5rem;
}
</style>
